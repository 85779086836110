import { Button } from '@naamdeo/shadcn-ui/components/button';
import { cn } from '@naamdeo/shadcn-ui/lib/utils';
import { Link } from '@remix-run/react';
import { useAtom } from 'jotai';
import { PanelsTopLeftIcon } from 'lucide-react';
import { useCallback } from 'react';
import { Menu } from '~/components/admin-panel/menu';
import { SidebarToggle } from '~/components/admin-panel/sidebar-toggle';
import { sidebarStateAtom } from '~/state/sidebar';

export function Sidebar() {
  const [sidebarState, setSidebarState] = useAtom(sidebarStateAtom);
  const toggleIsOpen = useCallback(() => {
    setSidebarState({ isOpen: !sidebarState.isOpen });
  }, [sidebarState.isOpen, setSidebarState]);

  return (
    <aside
      className={cn(
        'fixed left-0 top-0 z-20 h-screen -translate-x-full transition-[width] duration-300 ease-in-out lg:translate-x-0',
        sidebarState.isOpen === false ? 'w-[90px]' : 'w-72'
      )}
    >
      <SidebarToggle isOpen={sidebarState.isOpen} toggleIsOpen={toggleIsOpen} />
      <div className="relative flex h-full flex-col overflow-y-auto px-3 py-4 shadow-md dark:shadow-zinc-800">
        <Button
          className={cn(
            'mb-1 transition-transform duration-300 ease-in-out',
            sidebarState.isOpen === false ? 'translate-x-1' : 'translate-x-0'
          )}
          variant="link"
          asChild
        >
          <Link to="/" className="flex items-center gap-2">
            <PanelsTopLeftIcon className="mr-1 h-6 w-6" />
            <h1
              className={cn(
                'whitespace-nowrap text-lg font-bold transition-[transform,opacity,display] duration-300 ease-in-out',
                sidebarState.isOpen === false ? 'hidden -translate-x-96 opacity-0' : 'translate-x-0 opacity-100'
              )}
            >
              Courses Admin
            </h1>
          </Link>
        </Button>
        <Menu isOpen={sidebarState.isOpen} />
      </div>
    </aside>
  );
}
