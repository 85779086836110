import { ClerkApp } from '@clerk/remix';
import { rootAuthLoader } from '@clerk/remix/ssr.server';
import { LoaderFunctionArgs } from '@remix-run/cloudflare';
import { isRouteErrorResponse, Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteError } from '@remix-run/react';
import { Provider as JotaiProvider } from 'jotai';
import { UnhandledError } from '~/components/page-layout/unhandled-error-page';
import { RouteHandle } from '~/lib/route-handle';
import './global-styles/tailwind.css';
import { Layout as RootLayout } from './layouts/root-layout';

export const handle: RouteHandle = {
  breadcrumbText: 'Home',
};

export const loader = (args: LoaderFunctionArgs) => rootAuthLoader(args);

export const ErrorBoundary = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return (
      <UnhandledError title={error.statusText} statusCode={error.status} description={JSON.stringify(error.data)} />
    );
  } else if (error instanceof Error) {
    return <UnhandledError title="Something unexpected happened" statusCode={500} description={error.message} />;
  } else {
    return (
      <UnhandledError title="Something unexpected happened" statusCode={500} description={JSON.stringify(error)} />
    );
  }
};

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex, nofollow" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  return (
    <JotaiProvider>
      <RootLayout>
        <Outlet />
      </RootLayout>
    </JotaiProvider>
  );
}

export default ClerkApp(App);
