import { Button } from '@naamdeo/shadcn-ui/components/button';
import { cn } from '@naamdeo/shadcn-ui/lib/utils';
import { ChevronLeftIcon } from 'lucide-react';

interface SidebarToggleProps {
  isOpen: boolean | undefined;
  toggleIsOpen?: () => void;
}

export function SidebarToggle({ isOpen, toggleIsOpen }: SidebarToggleProps) {
  return (
    <div className="invisible absolute -right-[16px] top-[12px] z-20 lg:visible">
      <Button onClick={() => toggleIsOpen?.()} className="h-8 w-8 rounded-md" variant="outline" size="icon">
        <ChevronLeftIcon
          className={cn(
            'h-4 w-4 transition-transform duration-700 ease-in-out',
            isOpen === false ? 'rotate-180' : 'rotate-0'
          )}
        />
      </Button>
    </div>
  );
}
